@import "tailwindcss/base";
@import "tailwindcss/tailwind";
@import "tailwindcss/components";
// @import "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-toastr/toastr";

$greenThemeColor: #1fa385;

.greenish-blue {
  color: #00395e;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
iframe {
  height: 100vh;
  width: 100%;
}
button {
  @apply focus:outline-none;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.konvajs-content {
  margin: auto !important;
}

.rounded-4xl {
  border-radius: 36px;
}

.blocklyToolboxDiv {
  border-right: 1px solid #eaf0ff !important;
}

.blocklyToolboxCategory {
  border-bottom: 1px solid #eaf0ff !important;
}

.input-style {
  @apply border border-gray-200 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full h-12;
}

.text-area-style {
  @apply border border-gray-200 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full;
}

.label-style {
  @apply text-gray-500 absolute top-0 left-0 p-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out;
}
.floating-input > input::placeholder,
.floating-input > textarea::placeholder {
  // color: #00395e;
  color:  #363636;
}

.floating-input > input:focus,
.floating-input > input:not(:placeholder-shown),
.floating-input > textarea:focus,
.floating-input > textarea:not(:placeholder-shown) {
  @apply px-3;
}

.floating-input > input:focus ~ label,
.floating-input > textarea:focus ~ label,
.floating-input > input:not(:placeholder-shown) ~ label,
.floating-input > textarea:not(:placeholder-shown) ~ label {
  @apply -translate-y-10;
}

.joyride-step__title {
  font-size: 14px !important;
  color: #363636 !important;
}
.reverse-next-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.joyride-step__container {
  padding: 20px 10px 10px 10px !important;
  // width: 210px;
  border-radius: 6px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.joyride-step__close {
  width: 10px !important;
  height: 10px !important;
}
// .mid-left {
//   top: 42%;
//   left: 10%;
// }
.mid-left {
  top: 42%;
  left: 20%;
}
.mid-right {
  top: 42%;
  left: 75%;
}
.mid {
  top: 50%;
  left: 50%;
}
.code-block {
  counter-reset: css-counter 0;
  pre {
    counter-increment: css-counter 1;
    &::before {
      content: counter(css-counter);
      margin-right: 10px;
      color: #305167;
      text-align: right;
      display: inline-block;
      width: 2em;
      border-right: 1px #305167 solid;
      padding-right: 1em;
      margin-right: 1em;
    }
  }
}

// styles for create-new-variable Modal
.modal {
  height: 13rem;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  width: 40%;
}
#new_variable {
  display: none;
}

.float-right {
  float: right;
}

.bg-green-btn {
  background-color: $greenThemeColor;
}

.blockly-panel-btn {
  @apply bg-white ring-2 ring-button text-button p-2 font-bold mr-2 rounded-lg;
}

.general-btn {
  @apply m-2 px-4 py-2 font-bold shadow cursor-pointer;
  border-radius: 5px;
}

.green-btn:hover {
  background-color: #39c0a1;
}
.green-btn {
  @apply general-btn text-white ;
  background-color: #1fa385;
}

.blue-btn:hover {
  background-color: #0264a3;
}
.blue-btn {
  @apply general-btn text-white ;
  background-color: #00395E;
}

.custom-lg-btn {
  width: 172px !important;
  height: 38px;
}

.disabled-btn {
  @apply general-btn text-black;
  cursor: no-drop;
  background-color: #eaeaea;
  color: #8e8e8e;
}

.grey-btn:hover {
  background-color: #c0c0c0;
}
.grey-btn {
  @apply general-btn text-black ;
  background-color: #dfdfdf;
}

.red-btn:hover {
  background-color: #f88989;
}
.red-btn {
  @apply general-btn text-white ;
  background-color: #ff6e6e;
}
// .blocklyScrollbarBackground {
//   opacity: 0.1;
// }
// .blocklyScrollbarHandle {
//   fill: #6c6c6c !important;
//   fill-opacity: 0.5 !important;
// }
.blocklyDropDownDiv {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6c6c6c;
    opacity: 0.5;
  }

}

.cursor-no-drop {
  cursor: no-drop;
}